import type {
  EvmTokenMetadata,
  FlowNftContractMetadata,
  StakingContractMetadata,
  TokenPermissionRuleResponse,
} from '@collabland/sdk';
import * as yup from 'yup';
import { enableFriendTechFeatures } from './features';
import { getCollabClient } from '../sdk';
import { OptionType } from '../components/Select/Select';

export const tokenTypes: {
  value: string;
  label: string;
  isDisabled: boolean;
}[] = [
  { label: 'ERC20', value: 'ERC20', isDisabled: false },
  { label: 'ERC721', value: 'ERC721', isDisabled: false },
  { label: 'ERC1155', value: 'ERC1155', isDisabled: false },
  { label: 'Super Token', value: 'ERC777', isDisabled: false },
  { label: 'ROLL', value: 'ROLL', isDisabled: false },
  { label: 'POAP', value: 'POAP', isDisabled: false },
  { label: 'Staking contracts', value: 'staking', isDisabled: false },
  { label: 'Gnosis Safe', value: 'gnosis', isDisabled: false },
  { label: 'Immutable X', value: 'IMMUTABLE_X', isDisabled: false },
  { label: 'Loopring FT', value: 'LOOPRING_FT', isDisabled: false },
  { label: 'Loopring NFT', value: 'LOOPRING_NFT', isDisabled: false },
  { label: 'XRPL FT', value: 'XRPL_FT', isDisabled: false },
  { label: 'XRPL NFT', value: 'XRPL_NFT', isDisabled: false },
  { label: 'Moloch', value: 'Moloch', isDisabled: false },
  { label: 'OpenSea', value: 'OPEN_SEA', isDisabled: false },
  { label: 'TrustSwap Score', value: '6', isDisabled: true },
  { label: 'BEP20', value: 'BEP20', isDisabled: false },
  { label: 'BEP721', value: 'BEP721', isDisabled: false },
  { label: 'BEP1155', value: 'BEP1155', isDisabled: false },
  { label: 'NFTY.INK', value: '8', isDisabled: true },
  { label: 'NBA Top Shot', value: '12', isDisabled: true },
  { label: 'Solana FT', value: 'SOLANA_FT', isDisabled: false },
  { label: 'Solana NFT', value: 'SOLANA_NFT', isDisabled: false },
  { label: 'Tezos FA1.2', value: 'TEZOS_FA1.2', isDisabled: false },
  { label: 'Tezos FA2', value: 'TEZOS_FA2', isDisabled: false },
  { label: 'NIFTY', value: 'NIFTY', isDisabled: false },
  { label: 'FLOW FT', value: 'FLOW_FT', isDisabled: false },
  { label: 'FLOW NFT', value: 'FLOW_NFT', isDisabled: false },
  { label: 'RMRK', value: 'RMRK', isDisabled: false },
  { label: 'FT', value: 'NEP141', isDisabled: false },
  { label: 'NFT', value: 'NEP171', isDisabled: false },
  { label: 'Otterspace Badge', value: 'otterspace', isDisabled: false },
  {
    label: 'Axie Infinity Rule',
    value: 'AXIE',
    isDisabled: true,
  },
  // { label: 'Twitter', value: 'Twitter', isDisabled: false },
  { label: 'Bitcoin Ordinals', value: 'Bitcoin_Ordinals', isDisabled: false },
  { label: 'Bitcoin Stamps', value: 'Bitcoin_Stamps', isDisabled: false },
  { label: 'Humanity Score', value: 'HUMANITY_SCORE', isDisabled: false },
  { label: 'Friend.Tech', value: 'FRIEND_TECH', isDisabled: false },
];

export type ChainItem = {
  value: string;
  label: string;
  isDisabled: boolean;
  dropdownGroup?: string;
};

export type RoninToken = EvmTokenMetadata & { value: string; label: string };

export type AxieRule = {
  name: string;
  description: string;
  roleId: string;
  variables?: Record<string, any>;
  version?: string;
  requiresMetadata?: boolean;
  query?: string;
  asset: string;
};

// TODO: remove unused: https://app.clickup.com/t/p3fk9k
export const chainTypes: ChainItem[] = [
  {
    label: 'Mainnet',
    value: '1',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Rinkeby',
    value: '4',
    isDisabled: true,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Goerli',
    value: '5',
    isDisabled: true,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Polygon',
    value: '137',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Mumbai',
    value: '80001',
    isDisabled: true,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Moonbeam',
    value: '1284',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'BSC',
    value: '56',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Gnosis',
    value: '100',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Ronin',
    value: '2020',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Axie Infinity',
    value: '8000000002020',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Solana',
    value: '8000000000101',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Arbitrum One',
    value: '42161',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Arbitrum Nova',
    value: '42170',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Avalanche',
    value: '43114',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Optimism',
    value: '10',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Palm',
    value: '11297108109',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Q Chain',
    value: '35441',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Linea',
    value: '59144',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Base',
    value: '8453',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Blast',
    value: '81457',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'BitLayer',
    value: '200901',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Astar',
    value: '592',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Astar zkEVM',
    value: '3776',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Vitruveo',
    value: '1490',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Zeus',
    value: '34504',
    isDisabled: true,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'DOS',
    value: '7979',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Shibarium - Sponsored by PixelPalz.io',
    value: '109',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'SEI',
    value: '1329',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'ZKsync',
    value: '324',
    isDisabled: true,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Celo',
    value: '42220',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Viction',
    value: '88',
    isDisabled: true,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Sepolia (Ethereum Testnet)',
    value: '11155111',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  {
    label: 'Amoy (Polygon Testnet)',
    value: '80002',
    isDisabled: false,
    dropdownGroup: 'Ethereum Virtual Machine',
  },
  // {
  //   label: 'Mantle',
  //   value: '5001',
  //   isDisabled: false,
  //   dropdownGroup: 'Ethereum Virtual Machine',
  // },
  // {
  //   label: 'Zora',
  //   value: '7777777',
  //   isDisabled: false,
  //   dropdownGroup: 'Ethereum Virtual Machine',
  // },
  {
    label: 'Eluvio',
    value: '955305',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Flow',
    value: '8000000000001',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Kusama',
    value: '8000000000501',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'NEAR Testnet',
    value: '8000000000201',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'NEAR',
    value: '8000000000301',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Nifty',
    value: '0',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Polkadot',
    value: '2001',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Tezos',
    value: '8000000000401',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Immutable X',
    value: '8000000000601',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Loopring',
    value: '8000000000701',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'XRPL',
    value: '8000000000801',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  // {
  //   label: 'Twitter',
  //   value: 'twitter',
  //   // Pro Bot Feature then make it false
  //   isDisabled: false,
  //   dropdownGroup: 'Other',
  // },
  {
    label: 'XRPL NFT Devnet',
    value: '8000000000803',
    isDisabled: true,
    dropdownGroup: 'Other',
  },
  {
    label: 'Bitcoin',
    value: '8000000000901',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Gitcoin Passport',
    value: '8000000001001',
    isDisabled: false,
    dropdownGroup: 'Other',
  },
  {
    label: 'Friend.Tech',
    value: '8000000002001',
    isDisabled: !enableFriendTechFeatures,
    dropdownGroup: 'Other',
  },
];

export const tokenTypesWithMetadata: string[] = [
  'ERC721',
  'ERC1155',
  'BEP721',
  'BEP1155',
  'OPEN_SEA',
  'SOLANA_NFT',
  'NEP171',
  'FLOW_NFT',
  'staking',
  'TEZOS_FA2',
  'IMMUTABLE_X',
  'XRPL_NFT',
  'RMRK',
  'Twitter',
];

export const tokenTypesWithTokenId: string[] = [
  'ERC721',
  'ERC1155',
  'BEP721',
  'BEP1155',
  'OPEN_SEA',
  'TEZOS_FA2',
  'IMMUTABLE_X',
  'RMRK',
  'LOOPRING_NFT',
  'Bitcoin_Stamps',
  'Bitcoin_Ordinals',
];

export const OPENSEA_STOREFRONT_CONTRACT_ADDRESSES = [
  '0x2953399124f0cbb46d2cbacd8a89cf0599974963',
  '0x495f947276749ce646f68ac8c248420045cb7b5e',
];

export const OTTERSPACE_BADGE_CONTRACT_ADDRESSES = [
  // Contract address for Optimism
  '0x7f9279b24d1c36fa3e517041fdb4e8788dc63d25',
  // Contract address for Goerli
  '0xa6773847d3D2c8012C9cF62818b320eE278Ff722',
];

export const getChainNameById = (id: string): string =>
  chainTypes.find((el) => el.value === id)?.label || '';

export const getAllowListByChainType = (type: string): string[] => {
  switch (type) {
    case '1':
      return [
        'ERC20',
        'ERC721',
        'ERC1155',
        'ROLL',
        'POAP',
        'staking',
        'Moloch',
        'OPEN_SEA',
        'gnosis',
      ];
    case '4':
      return ['ERC20', 'ERC721', 'ERC777', 'ERC1155', 'ROLL', 'POAP', 'Moloch'];
    case '5':
      return ['ERC20', 'ERC721', 'ERC1155', 'gnosis', 'otterspace'];
    case '80001':
    case '80002':
      return ['ERC20', 'ERC721', 'ERC1155'];
    case '100':
      return [
        'ERC20',
        'ERC721',
        'ERC777',
        'ERC1155',
        'ROLL',
        'POAP',
        'Moloch',
        'OPEN_SEA',
        'gnosis',
      ];
    case '56':
      return ['BEP20', 'BEP721', 'BEP1155', 'ERC777', 'gnosis'];
    case '137':
      return [
        'ERC20',
        'ERC721',
        'ERC1155',
        'Moloch',
        'ERC777',
        'gnosis',
        'staking',
      ];
    case '10':
      return ['ERC20', 'ERC721', 'ERC1155', 'ERC777', 'gnosis', 'otterspace'];
    case '0':
      return ['NIFTY'];
    case '2001':
      return ['ERC20'];
    case '8000000002020':
      return ['AXIE'];
    case '1284':
    case '2020':
    case '35441':
    case '59144':
    case '8453':
    case '81457':
      return ['ERC20', 'ERC721', 'ERC1155', 'staking'];
    case '200901':
    case '592':
    case '3776':
    case '1490':
    case '34504':
    case '7979':
    case '109':
    case '1329':
    case '324':
    case '42220':
    case '88':
    case '11155111':
    case '5001':
    case '7777777':
    case '42170':
      return ['ERC20', 'ERC721', 'ERC1155'];
    case '42161':
    case '43114':
      return ['ERC20', 'ERC721', 'ERC1155', 'ERC777', 'gnosis'];
    case '955305':
      return ['ERC721'];
    case '11297108109':
      return ['ERC20', 'ERC721', 'ERC1155'];
    case '8000000000001':
      return ['FLOW_FT', 'FLOW_NFT'];
    case '8000000000101':
      return ['SOLANA_FT', 'SOLANA_NFT'];
    case '8000000000201':
      return ['NEP141', 'NEP171'];
    case '8000000000301':
      return ['NEP141', 'NEP171'];
    case '8000000000401':
      return ['TEZOS_FA1.2', 'TEZOS_FA2'];
    case '8000000000501':
      return ['RMRK'];
    case '8000000000601':
      return ['IMMUTABLE_X'];
    case '8000000000701':
      return ['LOOPRING_FT', 'LOOPRING_NFT'];
    case '8000000000801':
      return ['XRPL_FT', 'XRPL_NFT'];
    case '8000000000803':
      return ['XRPL_NFT'];
    case 'twitter':
      return ['Twitter'];
    case '8000000000901':
      return ['Bitcoin_Ordinals', 'Bitcoin_Stamps'];
    case '8000000001001':
      return ['HUMANITY_SCORE'];
    case '8000000002001':
      return ['FRIEND_TECH'];
    default:
      return [];
  }
};

export const getLinkByChainAndTokenType = (
  chainType = '',
  // tokenType = '',
): string => {
  switch (chainType) {
    case '1':
      return 'https://etherscan.io/';
    case '4':
      return 'https://rinkeby.etherscan.io/';
    case '100':
      return 'https://blockscout.com/poa/xdai';
    case '56':
      return 'https://bscscan.com/';
    case '137':
      return 'https://polygonscan.com/';
    default:
      return 'https://etherscan.io/';
  }
};

export const getNetworkNameByTokenType = (tokenType: string) => {
  switch (tokenType) {
    case 'SOLANA_FT':
    case 'SOLANA_NFT':
      return 'solana';
    case 'TEZOS_FA1.2':
    case 'TEZOS_FA2':
      return 'tezos';
    case 'FLOW_FT':
    case 'IMMUTABLE_X':
      return 'immutable_x';
    case 'LOOPRING_FT':
    case 'LOOPRING_NFT':
      return 'loopring';
    case 'XRPL_FT':
    case 'XRPL_NFT':
      return 'xrpl';
    case 'FLOW_NFT':
      return 'flow';
    case 'RMRK':
      return 'polkadot';
    case 'NEP141':
    case 'NEP171':
      return 'near';
    case 'Bitcoin_Ordinals':
    case 'Bitcoin_Stamps':
      return 'bitcoin';
    default:
      return 'evm';
  }
};

export const getSchemeByTokenType = (tokenType: string) => {
  switch (tokenType) {
    case 'SOLANA_FT':
    case 'SOLANA_NFT':
      return 'solana';
    case 'IMMUTABLE_X':
      return 'immutable_x';
    case 'LOOPRING_FT':
    case 'LOOPRING_NFT':
      return 'loopring';
    case 'XRPL_FT':
    case 'XRPL_NFT':
      return 'xrpl';
    case 'TEZOS_FA1.2':
    case 'TEZOS_FA2':
      return 'tezos';
    case 'FLOW_FT':
    case 'FLOW_NFT':
      return 'flow';
    case 'BEP_20':
    case 'BEP_721':
    case 'BEP1155':
      return tokenType.replace('BEP', 'ERC');
    case 'RMRK':
      return 'rmrk';
    case 'Twitter':
      return 'twitter';
    case 'Bitcoin_Ordinals':
    case 'Bitcoin_Stamps':
      return 'bitcoin';
    case 'HUMANITY_SCORE':
      return 'gitcoin';
    case 'FRIEND_TECH':
      return 'friendtech';
    default:
      return tokenType;
  }
};

export const getExtraFieldDataByTokenType = (
  tokenType: string,
): {
  address: { name: string; placeholder: string };
  collectionName: { name: string; placeholder: string };
  tokenId: { name: string; placeholder: string };
  unit: string;
} => {
  let address = { name: 'Address', placeholder: 'Input address' };
  let collectionName = {
    name: 'Collection Name',
    placeholder: 'Input collection name',
  };
  let tokenId = {
    name: 'Token ID (optional)',
    placeholder: 'Token ID(s) or a url pointing to a list of Token ID(s)',
  };
  let unit = 'tokens';
  switch (tokenType) {
    case 'SOLANA_NFT':
      address = {
        name: 'Adress(es)',
        placeholder: 'Input 1 or more values separated by comma',
      };
      break;
    case 'POAP':
      address = { name: 'Event Id', placeholder: 'Input event id' };
      break;
    case 'Moloch':
      unit = 'shares';
      break;
    case 'FLOW_FT':
      collectionName = {
        name: 'Public Path',
        placeholder: 'Input public path',
      };
      break;
    case 'XRPL_FT':
    case 'XRPL_NFT':
      address = { name: 'Issuer Address', placeholder: 'Input issuer address' };
      break;
    case 'ERC1155':
    case 'BEP1155':
      tokenId.name = 'Token ID';
      break;
    case 'LOOPRING_NFT':
      tokenId = {
        name: 'NFT ID (optional)',
        placeholder: 'NFT ID(s) or a url pointing to a list of NFT ID(s)',
      };
      break;
    case 'Bitcoin_Stamps':
      tokenId = {
        name: 'Stamp ID',
        placeholder: 'Stamp ID(s) or a url pointing to a list of Stamp ID(s)',
      };
      address = { name: 'Stamp Creator', placeholder: 'Input creator address' };
      break;
    case 'Bitcoin_Ordinals':
      collectionName = {
        name: 'Collection Name',
        placeholder: 'Input collection name',
      };
      break;
    case 'FRIEND_TECH':
      address = {
        name: 'Creator Wallet Address',
        placeholder: 'Input address',
      };
      unit = 'keys';
      break;
    default:
      address = { name: 'Address', placeholder: 'Input address' };
      break;
  }
  return { address, unit, tokenId, collectionName };
};

export const twitterAttributes = {
  followers_count: 'Followers count',
  following_count: 'Following count',
  tweet_count: 'Tweets count',
  listed_count: 'Listed count',
  verified: 'Verified',
  username: 'Username',
  name: 'Name',
  description: 'Description',
  created_at: 'Create date',
  id: 'ID',
  url: 'URL',
  following: 'Following',
};

export const twitterSelectOptions = Object.entries(twitterAttributes).map(
  ([value, label]) => ({ value, label }),
);

export const twitterMetadataValidationSchema = yup.object({
  followers_count: yup
    .number()
    .typeError('Followers count is not a ${type}')
    .min(1, 'Followers count cannot be a negative or zero number'),
  following_count: yup
    .number()
    .typeError('Following count is not a ${type}')
    .min(1, 'Following count cannot be a negative or zero number'),
  tweet_count: yup
    .number()
    .typeError('Tweets count is not a ${type}')
    .min(1, 'Tweets count cannot be a negative or zero number'),
  listed_count: yup
    .number()
    .typeError('Listed count is not a ${type}')
    .min(1, 'Listed count cannot be a negative or zero number'),
  verified: yup
    .string()
    .trim()
    .matches(/^(true|false)$/, "Verified must be 'true' or 'false'"),
  username: yup
    .string()
    .trim()
    .min(1, 'Username cannot be an empty string')
    .max(15, 'Username cannot exceed 15 characters')
    .matches(
      /^[A-Za-z0-9_]{1,15}$/,
      ' Username cannot have characters others than [A-Z.a-z,0-9,_]',
    ),
  name: yup.string().trim().min(1, 'Name cannot be an empty string'),
  description: yup
    .string()
    .trim()
    .min(1, 'Description cannot be an empty string'),
  created_at: yup
    .string()
    .trim()
    .min(1, 'Create date cannot be an empty string'),
  id: yup.string().trim().min(1, 'ID cannot be an empty string'),
  url: yup.string().trim().min(1, 'URL cannot be an empty string'),
  following: yup
    .string()
    .trim()
    .min(1, 'Following cannot be an empty string')
    .max(15, 'Following cannot have a username exceeding 15 characters')
    .matches(
      /^[A-Za-z0-9_]{1,15}$/,
      'Following cannot have a username with characters others than [A-Z.a-z,0-9,_]',
    ),
});

let FLOW_NFT_CONTRACTS: FlowNftContractMetadata[] = [];

export const getFlowNftContracts = () => FLOW_NFT_CONTRACTS;

export const setFlowNftContracts = (contracts: FlowNftContractMetadata[]) => {
  FLOW_NFT_CONTRACTS = contracts;
};

let STAKING_CONTRACTS: StakingContractMetadata[] = [];

export const getStakingContracts = () => STAKING_CONTRACTS;

export const setStakingContracts = (contracts: StakingContractMetadata[]) => {
  STAKING_CONTRACTS = contracts;
};

let RONIN_TOKENS: RoninToken[] = [];

export const getRoninTokens = () => RONIN_TOKENS;

export const setRoninTokens = (tokens: RoninToken[]) => {
  RONIN_TOKENS = tokens;
};

export const getBotUrl = (platform: string, communityId?: string) => {
  switch (platform) {
    case 'discord':
      if (communityId) {
        return `${process.env.REACT_APP_API_URL}/discord/bot-invite?guild_id=${communityId}`;
      }
      return `${process.env.REACT_APP_API_URL}/discord/bot-invite`;
    case 'telegram':
      return `https://telegram.me/${process.env.REACT_APP_TELEGRAM_BOT_ID}?startgroup=true`;
    default:
      return '';
  }
};

export const FETCHED_AXIE_RULES: AxieRule[] = [
  {
    name: 'Ronin Linked',
    description: 'has a ronin address and marketplace account',
    roleId: '887001490483466320',
    query: 'true',
    asset: '',
  },
  {
    name: 'Lunacian',
    description: 'owns any 1 Axies',
    roleId: '883496679905591339',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_all',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Axie Collector',
    description: 'owns 10 or more of any axie',
    roleId: '887002055326191666',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_all',
                value: '$ >= 10',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Land Owner',
    description: 'owns any land item',
    roleId: '887001795526799390',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_all',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Genesis Land Owner',
    description: 'owns any genesis land item',
    roleId: '989897496094466128',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_genesis',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Mystic Land Owner',
    description: 'owns any mystic land item',
    roleId: '989897392331587596',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_mystic',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Arctic Land Owner',
    description: 'owns any arctic land item',
    roleId: '989897225498943539',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_arctic',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Forest Land Owner',
    description: 'owns any forest land item',
    roleId: '989896984242581634',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_forest',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Savannah Land Owner',
    description: 'owns any savannah land item',
    roleId: '989896675827003402',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_savannah',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Luna Landing Land Owner',
    description: 'owns any luna landing land item',
    roleId: '1286317440216862722',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'lands_luna_landing',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Origin Axie Owner',
    description: 'owns at least one axie with the title Origin',
    roleId: '888183801870688288',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_origins',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Japanese Axie Owner',
    description: 'owns at least 1 axie with at least one Japanese body part',
    roleId: '986139745803440148',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_japanese',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Verified Mystic Axie Owner',
    description: 'owns at least 1 axie with a mystic body part',
    roleId: '984691958687629323',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_mystics_all',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'MEO Owner',
    description: 'owns at least 1 MEO or MEO II axie',
    roleId: '1171572495644635236',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        filters: [
          {
            condition: 'or',
            traits: [
              {
                trait_type: 'axies_meo1',
                value: '$ > 0',
              },
              {
                trait_type: 'axies_meo2',
                value: '$ > 0',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Double Mystic',
    description: 'owns at least 1 axie with 2 or more mystic body parts',
    roleId: '888184070931103755',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_mystics_2',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Triple Mystic',
    description: 'owns at least 1 axie with 3 or more mystic body parts',
    roleId: '888184155635073075',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_mystics_3',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Quad Mystic',
    description: 'owns at least 1 axie with 4 or more mystic body parts',
    roleId: '888184256801693817',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_mystics_4',
                value: '$ >= 1',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Christmas Axie Owner',
    description: 'owns at least one Christmas Axie / Christmas Axie Body',
    roleId: '999362115477442745',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        filters: [
          {
            condition: 'or',
            traits: [
              {
                trait_type: 'axies_xmas',
                value: '$ > 0',
              },
              {
                trait_type: 'axies_xmas_body',
                value: '$ > 0',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Summer Axie Owner',
    roleId: '999362292611285073',
    description: 'owns at least one Summer Axie / Summer Axie Body',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        filters: [
          {
            condition: 'or',
            traits: [
              {
                trait_type: 'axies_summer',
                value: '$ > 0',
              },
              {
                trait_type: 'axies_summer_body',
                value: '$ > 0',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Frosty Body Owner',
    description: 'owns at least one Axie Xmas Body',
    roleId: '999366221008998471',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_xmas_body',
                value: '$ > 0',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'Shiny Owner',
    description: 'owns at least one Axie Shiny',
    roleId: '999366329511448659',
    query: '$ownsTraits($, $_filter)',
    variables: {
      _filter: {
        condition: 'or',
        asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
        filters: [
          {
            condition: 'and',
            traits: [
              {
                trait_type: 'axies_shiny',
                value: '$ > 0',
              },
            ],
          },
        ],
      },
    },
    version: '2.1.0',
    requiresMetadata: true,
    asset: 'axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C',
  },
  {
    name: 'RON Token Owner',
    description: 'Owns at least 100 RON tokens',
    roleId: '1045078690758922321',
    query: '$ownsBalance($, $_filter)',
    variables: {
      _filter: {
        minCount: '100000000000000000000',
        asset: 'axie:ronin/native:ron',
      },
    },
    asset: 'axie:ronin/native:ron',
    version: '2.1.0',
    requiresMetadata: false,
  },
];
let AXIE_RULES: AxieRule[] = [];
export const getAxieRules = () =>
  getCollabClient()
    .axieRules.getAxieRuleList()
    .catch((e) => console.error(e));

export const getAixeRulesOptions = (
  rules: TokenPermissionRuleResponse[],
): OptionType[] => {
  return rules.map((r) => {
    return {
      label: `${r.name} - ${r.description}`,
      value: r.name ?? '',
    };
  });
};

export const setAxieRules = (rules: AxieRule[]) => {
  AXIE_RULES = rules;
};
