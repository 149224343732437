import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'store/hooks';
import {
  getBackgroundCheckStatus,
  updateCommunityConfig,
} from 'store/community/actionCreators';
import {
  checkCommunityBotConf,
  hideBotBanner,
  showBotBanner,
  unlinkSubRedditToken,
} from 'store/discordBot';
import { enableProBotFeaturesV1 } from 'constants/features';
import Modal from 'components/Modal';
import Switch from 'components/Switch';
import Button from 'components/Button';
import NotificationBanner from 'components/NotificationBanner';
import HeaderSearch from 'components/HeaderSearch';
import LinkCopy from 'components/LinkCopy';
import BalanceCheck from './BalanceCheck/BalanceCheck';
import CustomMessage from './CustomMessage/CustomMessage';
import { CheckIcon, CloseIcon, EditIcon } from 'icons';

import fonts from 'styles/typography.module.scss';
import s from './BotConfig.module.scss';
import usePlanHook from '../../hooks/usePlanHook';
import { FeatureIdentifiers } from '../../store/types';
import CommunityConfigToggle from './CommunityConfigToggle/CommunityConfigToggle';
import Badge from '../Badge';
import useCurrentUrl from '../../hooks/useCurrentUrl';
import { FaArrowUp } from 'react-icons/fa6';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';

const BotConfig: React.FC = () => {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = useCurrentUrl();
  const [loading, setLoading] = useState({
    checkBotLoading: false,
    unlinkLoading: false,
    updateCredLoading: false,
  });
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [bgBalanceCheck, setBgBalanceCheck] = useState(false);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const communityCheck = useGetCurrentCommunity();
  const { community: activeCommunity } = communityCheck;
  const botCheckResponse = useSelector((state) => {
    if (state.discordBot[activeCommunityId]) {
      return state.discordBot[activeCommunityId].responseData;
    }
    return [];
  });

  const showBanner = useSelector((state) => {
    if (state.discordBot[activeCommunityId])
      return state.discordBot[activeCommunityId].showBanner;
    return null;
  });

  const platform = useSelector((state) => state.user.platform);

  const { checkFeature } = usePlanHook(communityCheck);
  const redditInvitationLink = activeCommunity
    ? `${process.env.REACT_APP_API_URL}/reddit/authorize?state=${activeCommunityId}&subreddit=${activeCommunity.name}&redirect_uri=${process.env.REACT_APP_REDDIT_REDIRECT_URI}`
    : undefined;
  const telegramInvitationLink = activeCommunity?.link;
  const inviteLink =
    platform === 'reddit' ? redditInvitationLink : telegramInvitationLink;
  useEffect(() => {
    if (activeCommunity) {
      setShowWelcomeMessage(activeCommunity.isWelcomeMsgEnabled);
      setBgBalanceCheck(!activeCommunity.disableBkgBalCheck);
      setWelcomeMessage(activeCommunity.welcomeMessage);
    }
  }, [activeCommunityId]);

  useEffect(() => {
    if (activeCommunity?.pk) {
      getBackgroundCheckStatus(activeCommunity.pk)
        .then((result) => {
          if (!result.allowed) {
            setErrorMessage(
              `Reduce ${
                platform === 'discord' ? 'TGRs' : 'TGAs'
              } or upgrade subscription plan to resolve.`,
            );
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, [activeCommunityId]);
  const submitWelcomeMessage = () => {
    setIsEditMessage(false);

    dispatch(
      updateCommunityConfig(activeCommunityId, {
        welcomeMessage,
      }),
    );
  };

  const toggleShowWelcomeMessage = (isEnabled: boolean) => {
    dispatch(
      updateCommunityConfig(activeCommunityId, {
        isWelcomeMsgEnabled: isEnabled,
      }),
    );

    setShowWelcomeMessage(isEnabled);
  };

  const toggleBgBalanceCheck = (isEnabled: boolean) => {
    dispatch(
      updateCommunityConfig(activeCommunityId, {
        disableBkgBalCheck: !isEnabled,
      }),
    );

    setBgBalanceCheck(isEnabled);
  };

  const checkBotConfig = async () => {
    setLoading({ ...loading, checkBotLoading: true });

    const dispatchCheckCommunityBot = dispatch(
      checkCommunityBotConf({ communityId: activeCommunityId }),
    );

    try {
      await dispatchCheckCommunityBot.unwrap();
    } finally {
      dispatch(showBotBanner({ communityId: activeCommunityId }));
      setLoading({ ...loading, checkBotLoading: false });
    }
  };

  const unlinkRedditToken = async () => {
    setLoading({ ...loading, unlinkLoading: true });

    const dispatchUnlinkSubRedditToken = dispatch(
      unlinkSubRedditToken({ communityId: activeCommunityId }),
    );

    try {
      await dispatchUnlinkSubRedditToken.unwrap();
    } finally {
      localStorage.removeItem('collabLandToken');
      navigate('/login', { state: { from: '/' } });
    }
  };

  return (
    <>
      <HeaderSearch />
      <div className={s.content}>
        {showBanner && (
          <NotificationBanner
            close={() =>
              dispatch(hideBotBanner({ communityId: activeCommunityId }))
            }
            msgClass={botCheckResponse.length > 1 ? 'warningMsg' : 'successMsg'}
            msgContents={botCheckResponse}
            msgTitle={
              botCheckResponse.length > 1
                ? 'WARNING: Bot is NOT configured correctly'
                : 'Check complete'
            }
          />
        )}
        {errorMessage && (
          <NotificationBanner
            close={() => setErrorMessage(undefined)}
            msgClass={'warningMsg'}
            msgContents={[
              {
                msg: (
                  <>
                    Reduce {platform === 'discord' ? 'TGRs' : 'TGAs'} or{' '}
                    <Link to={`/dashboard/${activeCommunityId}/subscription`}>
                      upgrade subscription plan
                    </Link>{' '}
                    to resolve.
                  </>
                ),
              },
            ]}
            msgTitle={`Warning: Subscription ${
              platform === 'discord' ? 'TGRs' : 'TGAs'
            } exceeded, balance check is paused.`}
          />
        )}
        <h5 className={cx(fonts.title1, s.title)}>Bot Config</h5>
        {(platform === 'discord' || platform === 'reddit') && (
          <>
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>
                  Check Bot Configuration
                </h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Check if the bot is properly configured.
                </p>
              </div>

              <Button
                color="secondary-light"
                className={
                  loading.checkBotLoading
                    ? s.checkBotButtonLoading
                    : s.checkBotButton
                }
                onClick={() => checkBotConfig()}
                data-tracking-info={JSON.stringify({
                  id: 'button:check-bot-config:click',
                  server_id: activeCommunityId,
                })}
              >
                {loading.checkBotLoading
                  ? 'Checking...'
                  : 'Check configuration'}
              </Button>
            </div>
            {platform === 'discord' && enableProBotFeaturesV1 && (
              <>
                <div className={s.separator} />
                <CommunityConfigToggle
                  activeCommunityId={activeCommunityId}
                  disabled={!checkFeature(FeatureIdentifiers.TOGGLE_UPDATES)}
                  description={
                    "Community messages are relevant updates and services specifically tailored for your members' needs and interests. Toggle available for Premium and higher tiers."
                  }
                  name={'Community Messaging'}
                  keyword={'disablePromotionalUpdates'}
                />
                <div className={s.row}>
                  <div className={s.column}>
                    <h6 className={cx(s.subtitle, fonts.title3)}>
                      Balance Check
                    </h6>

                    <p className={cx(s.text, fonts.caption1)}>
                      Balance check ensures members remain qualified to be in
                      your server or group. <br /> Basic tier checks occur every
                      7 days. <br /> Premium and higher tiers are checked every
                      24 hours. <br /> Toggle available for Basic and higher
                      tiers.
                    </p>
                  </div>
                  <Switch
                    onChange={toggleBgBalanceCheck}
                    isChecked={
                      !!checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK) &&
                      bgBalanceCheck
                    }
                    isDisabled={
                      !checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK)
                    }
                    data-tracking-info={JSON.stringify({
                      id: 'switch:bg-balance-check:toggle',
                      server_id: activeCommunityId,
                    })}
                  />
                </div>
                {checkFeature(FeatureIdentifiers.ON_DEMAND_BALANCE_CHECK) && (
                  <BalanceCheck
                    onChange={toggleBgBalanceCheck}
                    isChecked={bgBalanceCheck}
                  />
                )}
                <div className={s.separator} />
                <CustomMessage />
              </>
            )}
            {/*{enableProBotFeaturesV2 && (*/}
            {/*  <>*/}
            {/*    <div className={s.separator} />*/}
            {/*    <div className={s.separator} />*/}
            {/*    <WhitelistedRoles />*/}
            {/*    <div className={s.separator} />*/}
            {/*    <AddressShare />*/}
            {/*  </>*/}
            {/*)}*/}
          </>
        )}
        {platform === 'telegram' && (
          <>
            <div className={s.separator} />
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>Balance Check</h6>
                <p className={cx(s.text, fonts.caption1)}>
                  Balance check ensures members are qualified to be in your
                  group.
                  <br /> Starter tier checks occur every 7 days.
                  <br /> Premium and higher tiers are checked every 24 hours.
                </p>
              </div>
              <Switch
                onChange={toggleBgBalanceCheck}
                isChecked={
                  // !!checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK) &&
                  bgBalanceCheck
                }
                isDisabled={
                  botCheckResponse?.length > 1
                  // || !checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK)
                }
                data-tracking-info={JSON.stringify({
                  id: 'switch:bg-balance-check:toggle',
                  server_id: activeCommunityId,
                })}
              />
            </div>
            {checkFeature(FeatureIdentifiers.ON_DEMAND_BALANCE_CHECK) && (
              <BalanceCheck
                onChange={toggleBgBalanceCheck}
                isChecked={bgBalanceCheck}
              />
            )}
            <div className={s.separator} />
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>
                  Member Greeting
                </h6>
                <p className={cx(s.text, fonts.caption1)}>
                  This is a fun welcome for verified members when they join your
                  group.
                </p>
              </div>
              <Switch
                onChange={toggleShowWelcomeMessage}
                isChecked={showWelcomeMessage}
              />
            </div>

            <div className={s.separator} />

            {showWelcomeMessage && (
              <div className={s.form}>
                <div className={s.formHeader}>
                  <div className={s.column}>
                    <h6 className={cx(s.subtitle, fonts.title3)}>
                      Welcome Message <Badge large>Pro</Badge>
                    </h6>
                    <p className={cx(s.text, fonts.caption1)}>
                      Customize the message prospective members see before
                      verifying.
                    </p>
                    <p className={cx(s.text, fonts.caption1)}>
                      Suggestion: Share information about the requirements to
                      join the group.
                    </p>
                  </div>
                </div>

                <textarea
                  placeholder="Enter welcome message"
                  rows={8}
                  disabled={!isEditMessage}
                  className={s.textarea}
                  style={{
                    // @ts-ignore
                    '--disabled': !isEditMessage ? '#8b8b8b' : '#a17408',
                  }}
                  value={
                    checkFeature(FeatureIdentifiers.CUSTOMIZE_DM)
                      ? welcomeMessage || ''
                      : ''
                  }
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                />

                {checkFeature(FeatureIdentifiers.CUSTOMIZE_DM) ? (
                  <div className={s.rowReverse}>
                    {isEditMessage ? (
                      <>
                        <button
                          className={s.button}
                          type="button"
                          onClick={submitWelcomeMessage}
                        >
                          <CheckIcon className={s.icon} />
                          Save
                        </button>
                        <button
                          className={cx(s.cancel, s.button)}
                          type="button"
                          onClick={() => {
                            setIsEditMessage(false);
                            if (activeCommunity) {
                              setWelcomeMessage(activeCommunity.welcomeMessage);
                            }
                          }}
                        >
                          <CloseIcon className={s.icon} />
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className={s.button}
                        type="button"
                        onClick={() => setIsEditMessage(true)}
                      >
                        <EditIcon className={s.icon} />
                        Edit
                      </button>
                    )}
                  </div>
                ) : (
                  <div className={s.rowReverse}>
                    <button
                      className={s.button}
                      type="button"
                      onClick={() => {
                        navigate(
                          `/dashboard/${activeCommunity?.communityId}/subscription?redirectUri=${currentUrl}`,
                        );
                      }}
                    >
                      <FaArrowUp color={'#FFFFFF'} className={s.icon} />
                      Upgrade
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {(platform === 'telegram' || platform === 'reddit') && (
          <>
            <div className={s.separator} />
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>Invite Link</h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Share your group's unique invite link for members to join your
                  token-gated chat.
                </p>
              </div>
              <div className={s.linkarea}>
                {inviteLink && (
                  <LinkCopy
                    label="Copy link:"
                    value={inviteLink}
                    isUrl
                    data-tracking-info={JSON.stringify({
                      id: 'button:copy-telegram-invite-link:click',
                      server_id: activeCommunityId,
                    })}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {platform === 'reddit' && (
          <>
            <div className={s.separator} />
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>Unlink token</h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Remove Collab.land from your subreddit
                </p>
              </div>

              <Button
                color="danger-outline"
                className={
                  loading.unlinkLoading
                    ? s.checkBotButtonLoading
                    : s.checkBotButton
                }
                onClick={() => setShowUnlinkModal(true)}
              >
                {loading.unlinkLoading ? 'Working...' : 'Unlink'}
              </Button>
            </div>
          </>
        )}
        {showUnlinkModal && (
          <Modal className={s.modal}>
            <h4 className={cx(fonts.title2, s.title)}>Unlink Subreddit</h4>

            <p className={cx(fonts.body1, s.text)}>
              Are you sure you want to unlink your token? This action will log
              you out and remove the subreddit from the list.
            </p>

            <div className={s.row}>
              <Button
                type="button"
                color="secondary"
                className={s.cancel}
                onClick={() => setShowUnlinkModal(false)}
              >
                Cancel
              </Button>

              <Button
                type="button"
                color="danger"
                className={s.submit}
                onClick={() => {
                  unlinkRedditToken();
                  setShowUnlinkModal(false);
                }}
              >
                Unlink
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default BotConfig;
